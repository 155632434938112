import { fontFamily } from './fonts/fontFamily'

const large = 1700, 
		mLarge = 1400,
		medium = 1200,
		small = 840,
		mobile = 540, 
		navMobile = 680

export default {
	space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
	fontSizes: [12, 14, 16, 20, 24, 36, 48, 80, 96],
	fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
	width: [16, 32, 64, 128, 256],
	heights: [16, 32, 64, 128, 256],
	fonts: {
		logo: fontFamily.header,
		header: fontFamily.header,
		body: fontFamily.body
	},
	colors: {
		black:'#0d0d0d',
		white:'#FFFFFF',
		gold: '#B8860B',
		lightGrey: '#BCBCBC'
	},
	containerSizes: {
		large: large,
		mLarge: mLarge,
		medium: medium,
		small: small,
		mobile: mobile,
		navMobile: navMobile
	},
	fontSizes: {
		header: 54,
		subheader: 32,
		content: 16,
	},
	breakpoints: {
		large: `${large}px`,
		mLarge: `${mLarge}px`,
		medium: `${medium}px`,
		small: `${small}px`,
		mobile: `${mobile}px`,
		navMobile: `${navMobile}px`
	},
	hPadding: {
		medium: "50px",
		small: "20px"
	}
}
