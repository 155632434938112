import React, { createContext, useState } from "react";

export const AppContext = createContext({
	curPage: null,
	setCurPage: () => {},
	firstTimer: true,
	setFirstTimer: () => {},
	musicStatus: false,
	setMusicStatus: () => {},
})

function StateProvider({ children }) {

    const [appState, setAppState] = useState({
	    curPage: null,
	    firstTimer:true,
		musicStatus: false,
		isMobile: null
	});
	const value={ appState, setAppState }

  return (
    <AppContext.Provider value={value}>
        {children}
    </AppContext.Provider>
	)
}

export default ({ element }) => (
	<StateProvider>
		{element}
	</StateProvider>
)