import React, { useRef, useEffect, useState, useContext } from 'react'

import { StaticQuery, graphql, navigate } from 'gatsby'
import _ from 'lodash'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import * as v from './variants'
import { css } from '@emotion/core'
import { modal, subheader } from '../../styles/mixins'
import theme from '../../styles/theme'
import { fontFamily } from "../../styles/fonts/fontFamily"
import VideoModule from '../modules/video'
import { AppContext } from '../../context/stateProvider'
import SoundBar from './soundBar'
import { use100vh } from 'react-div-100vh'

export default function Intro({ children }) {
  const [curHover, setCurHover] = useState(0)
  const hoverRefs = useRef([])

  const { appState, setAppState } = useContext(AppContext)

  useEffect(() => {
    const activateHomepage = () => {
      if (location.pathname === '/') {
        setAppState((appState) => ({
          ...appState,
          curPage: 0,
        }))
      }
    }
    activateHomepage()

    // on back button //
    window.onpopstate = (e) => {
      activateHomepage()
	}
	if (/Mobi|Android/i.test(navigator.userAgent)) {
		// mobile!
		setAppState((appState) => ({
			...appState,
			isMobile: true,
		  }))
	} else {
		setAppState((appState) => ({
			...appState,
			isMobile: false,
		  }))
	}
  }, [])

  // mouseover functionality //

  let isMousedOver = null
  function mouseOverFunc(i) {
    setCurHover(i)
    isMousedOver = true
  }
  function mouseOutCallback() {
    if (!isMousedOver) {
      setCurHover(0)
    }
  }
  function mouseOutFunc() {
    isMousedOver = false
    setTimeout(function () {
      mouseOutCallback()
    }, 10)
  }

  function clickFunc(page, i) {
    setAppState((appState) => ({
      ...appState,
      curPage: i,
      firstTimer: false,
    }))
    const slug = _.get(page, 'node.title').toLowerCase()
    setTimeout(function () {
      navigate(`/${slug}`)
    }, 300)
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
          allSanityPageTemplate(sort: { fields: [sortNumber], order: ASC }) {
            edges {
              node {
                title
                header
                video {
                  asset {
                    _key
                    _type
                    assetId
                    playbackId
                    filename
                    status
                    thumbTime
                  }
                }
              }
            }
          }
        }
    `}
        render={ data => {
            const pages = _.get(data, 'allSanityPageTemplate.edges')
            const firstPage = pages[0]
            const firstVideo = _.get(firstPage, 'node.video.asset')
            const height = use100vh()

            return (
                <>
                    <SoundBar
                      video={firstVideo}
                    />
                    {appState.firstTimer === false &&
                        <ModalContainer
                            status={appState.curPage === 0}
                            onMouseOut={() => mouseOutFunc()}
                            css={css`
                                height: ${height}px;
                            `}
						            >
                            <FadeInContainer
                                variants={v.videoVariants}
                                initial='hidden'
                                animate='visible'
                                transition='transition'
                                css={css`
                                    height: ${height}px;
                                `}
                            >
                                {appState.isMobile === false &&
                                    <>
                                        {pages.map((page, i) => { // loop through the videos
                                            const video = _.get(page, 'node.video.asset')
                                                return (
                                                    <VideoHolder
                                                        key={i}
                                                        className={curHover === i ? 'active' : 'inactive'}
                                                        css={css`
                                                            height: ${height}px;
                                                            > div > div {
                                                                height: ${height}px;
                                                            }
                                                        `}
                                                    >
                                                        <VideoOverlay
                                                            css={css`
                                                                height: ${height}px;
                                                            `}
                                                        />
                                                        <VideoModule video={video} />
                                                    </VideoHolder>
                                                )
                                        })}
                                    </>
                                }
                                {appState.isMobile &&
                                    <VideoHolder
                                        className="active"
                                        css={css`
                                            height: ${height}px;
                                            > div > div {
                                                height: ${height}px;
                                            }
                                        `}
                                    >
                                        <VideoOverlay
                                            css={css`
                                                height: ${height}px;
                                            `}
                                        />
                                        <VideoModule video={firstVideo} />
                                    </VideoHolder>
                                }
                            </FadeInContainer>
                            <Controls
                                isMobile={appState.isMobile}
                                css={css`
                                    height: ${height}px;
                                `}
                            >
                                {pages.map((page, i) => { // loop through the hover controls
                                    return (
                                        <>
                                            {i !== 0 &&
                                                <motion.div
                                                    onClick={() => clickFunc(pages[i], i)}
                                                    key={i}
                                                    onMouseOver={() => mouseOverFunc(i)} 
                                                    ref={hoverRefs.current[i]}
                                                    variants={v.controlVariants}
                                                    initial='inactive'
                                                    animate={'active'}
                                                    custom={i}
                                                >
                                                <motion.h2
                                                    variants={v.pageTitleVariants}
                                                >
                                                    {page.node.title}
                                                </motion.h2>
                                            </motion.div>
                                            }
                                        </>
                                    )
                                })}
                            </Controls>
                        </ModalContainer>
					}
                    {children}
                </>
            )
        }}
    />
    )
  }

const ModalContainer = styled.section`
  color: white;
  opacity: 1;
  transition: opacity 0.3s;
  transition-delay: 0;
  z-index: 10000;
  ${modal}
  flex-direction:column;
  ${({ status }) =>
    status === false &&
    css`
      opacity: 0;
      pointer-events: none;
      display:none;
    `}
`
const FadeInContainer = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
`
export const VideoHolder = styled.div`
    position:absolute;
    width:100vw;
    opacity:0;
    transition:opacity .4s;
    &.active {
        opacity:1;
        transition:opacity .2s;
    }
    >div >div {
        width:100vw;
    }
    video {
        object-fit: cover;
    }
`
const Controls = styled.div`
    z-index: 10000;
    position: relative;
    display: flex;
    width: 100%;
    cursor: pointer;

    > div {
        width: 25%;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s;

        &:last-child {
         border-right: 0;
        }

        ${({ isMobile }) =>
            isMobile && css`
                width: 100%;
                &:first-child {
                  padding-top: 3rem;
                }
              
              @media(min-width:${theme.breakpoints.navMobile}) {
                &:first-child {
                  padding-top: 0;
                }
              }
            `
        }

        h2 {
            color:white;
            font-family:${theme.fonts.header};
            font-size:32px;
            transition:all .4s;

            @media(min-width:${theme.breakpoints.navMobile}) {
              font-size:48px;
            }
        }

        ${({ isMobile }) =>
            !isMobile && css`
                &:hover {
                    width:50%;
                    flex-grow:1;
                    cursor: pointer;
                    h2 {
                        transform:scale(1.3);
                        transform-origin:center;
                    }
                }
            `
        }
    }

    @media (max-width:600px) {
        flex-direction:column;
    
        >div {
            border-right:none;
            border-bottom:1px solid rgba(255, 255, 255, .3);
            width:100%;
        }
    }

`
export const VideoOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.4;
  z-index: 1;
  width: 100%;
`
const Headline = styled.h2`
  ${subheader}
  color:${theme.colors.white};
  margin-bottom: 2rem;
`
const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
`
const Button = styled.button`
  color: ${theme.colors.white};
  font-family: ${fontFamily.header};
  font-size: 1.3rem;
  background-color: transparent;
  height: 10rem;
  width: 10rem;
  margin: 20px;
  padding: 10px;
  display: inline-block;
  border-radius: 50%;
  border: solid 1px ${theme.colors.white};

  &:hover {
    background-color: ${theme.colors.gold};
    border: solid 1px ${theme.colors.gold};
  }
`
const Audio = styled.audio`
  position: relative;
  z-index: 999999;
`
