import styled from '@emotion/styled'
import theme from '../theme'
import { motion } from 'framer-motion'

const CTA = styled(motion.button)`
	background-color:${theme.colors.gold};
	color:${theme.colors.white};
	padding:12px 30px;
	border-radius:30px;
	border:none;
	font-family:${theme.fonts.body};
	font-size: 1rem;

	a {
	color: inherit;
	}

`

export default CTA
