/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */


import React from "react";
import Intro from "./src/components/intro"
import StateProvider from './src/context/stateProvider'

export const wrapPageElement = ({ element, props }) => {

	return (
		<Intro {...props}>
			{element}
		</Intro>
	)
}

export const wrapRootElement = StateProvider