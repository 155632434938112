import React, { useRef, useEffect } from 'react'
import SanityMuxPlayer from 'sanity-mux-player'
import { css } from '@emotion/core'

const VideoModule = ({ video, clipPath }) => {
  const videoEl = useRef(null)

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error('Error attempting to play', error)
      })
  }

  useEffect(() => {
    attemptPlay()
  }, [])

  return (
    <>
      {video && (
        <SanityMuxPlayer
          assetDocument={video}
          autoload={true}
          autoplay={true}
          height={`100%`}
          loop={true}
          muted={true}
          showControls={false}
          width={`100%`}
          playsInline={true}
        />
      )}
    </>
  )
}
export default VideoModule
