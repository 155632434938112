// import { fontFamily } from "./fonts/fontFamily"
import theme from "./theme"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

export const fluid = (
	property,
	minSize,
	maxSize,
	minScreenWidth,
	maxScreenWidth
) => {
	return `
		${property}: calc( ${minSize}px + (${maxSize - minSize}) 
		* ((100vw - ${minScreenWidth}px) / (${maxScreenWidth - minScreenWidth})) );

        @media screen and (min-width:${maxScreenWidth}px) {
            ${property}: ${maxSize}px;
        }
        @media screen and (max-width:${minScreenWidth}px) {
            ${property}: ${minSize}px;
        }
    `
}
export const standardWidths = css`
	width:calc(100% - (${theme.hPadding.small * 2}));
	@media(min-width:${theme.breakpoints.small}) {
		width:calc(100% - (${theme.hPadding.medium * 2}));
	}
`
export const standardHPadding = css`
		padding-left:${theme.hPadding.small};
		padding-right:${theme.hPadding.small};
	@media(min-width:${theme.breakpoints.small}) {
		padding-left:${theme.hPadding.medium};
		padding-right:${theme.hPadding.medium};

	}
`
export const MaxWidthContainer = styled.div`
	${standardHPadding}
	max-width: ${props =>
		props.maxW ? props.maxW : `${theme.containerSizes.medium}px`};
	margin: auto;
	position: relative;
`
export const bigHeader = css`
	font-family: ${theme.fonts.header};
	font-weight: normal;
	position:relative;
	color: ${theme.colors.black};
	${fluid("font-size", theme.fontSizes.header * .7, theme.fontSizes.header, theme.containerSizes.small, theme.containerSizes.mLarge)}
`
export const TextHeader = styled.h2`
	
`

export const subheader = css`
	font-family:${theme.fonts.body};
	color:${theme.colors.black};
	text-align:center;
	${fluid("font-size", theme.fontSizes.subheader * .7, theme.fontSizes.subheader, theme.containerSizes.small, theme.containerSizes.mLarge)}
`

export const pStyle = css`
	line-height:1.8;
	font-family: ${theme.fonts.body};
	font-size: 1rem;
	font-weight: 300;
`
export const Paragraph = styled.p`
	${pStyle}

	${({ textColor }) =>
		textColor &&
		css`
			color: ${textColor};
		`}
`
export const linkActive = css`
	&.active {
		color: ${theme.colors.white};
		border-bottom: 1px solid ${theme.colors.gold};
		padding-bottom: 2px;
		&:hover {
			span {
				&:before {
					transform: scale3d(1, 0, 1) !important;
					transform-origin: 50% 100% !important;
				}
			}
		}
	}
`
export const linkHoverState = css`
	span {
		& :before {
			content: "";
			position: absolute;
			z-index: -1;
			bottom: 0;
			left: -7%;
			width: 105%;
			height: 50%;
			background: ${theme.colors.squash};
			transform: scale3d(1, 0, 1);
			transform-origin: 50% 100%;
			transition: transform 0.3s;
			transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
		}
	}

	&:hover,
	&.active {
		span {
			&:before {
				transform: scale3d(1, 1, 1);
				transform-origin: 50% 0%;
			}
		}
	}
`
export const alternatingPostStyles = `
	&:nth-of-type(3n+1) {
		background:red;
	}
	&:nth-of-type(3n+2) {
		background:blue;
	}
	&:nth-of-type(3n+3) {
		backgorund:yellow;
	}
`
export const VideoContainer = styled.div`
	width: 120%;
	position: relative;
`
export const modal = css`
	height:100vh;
	width:100vw;
	position:fixed;
	display:flex;
	align-items:center;
	justify-content:center;
	top:0;
`
