import React, { useRef, useState, useContext, useEffect } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import styled from '@emotion/styled'
import soundOn from "../../svgs/volume-on.svg"
import soundOff from "../../svgs/volume-off.svg"
import { modal, pStyle } from '../../styles/mixins'
import theme from '../../styles/theme'
import myAudio from '../../audio/intro-music.mp3'
import { AppContext } from '../../context/stateProvider'
import { css } from '@emotion/core'
import { motion } from 'framer-motion'
import { use100vh } from 'react-div-100vh'
import IntroLogo from './animated-components/IntroLogo'
import VideoModule from "../modules/video"
import { VideoHolder, VideoOverlay } from './index'
import * as v from './variants'
import CTA from "../../styles/ui/cta"

const SoundBar = ({ video }) => {
	const { appState, setAppState } = useContext(AppContext);
	console.log("appstate: ", appState)
	const music = useRef(null)
	const [playStatus, setPlayStatus] = useState(true)
	const [hoverability, setHoverability] = useState(false)

	const playMusic = () => {
		if(music && music.current) {
			music.current.audioEl.current.play()
		}
		setPlayStatus(true)
		setAppState(appState => ({ 
			...appState,
			firstTimer:false
		}))
		window.localStorage.setItem("soundstatus", "on")
		if(appState.curPage === 0) {
			setTimeout(() => {
				setHoverability(true)
			}, 5000)
		} else {
			setHoverability(true)
		}
		
	}
	const pauseMusic = () => {
		music.current.audioEl.current.pause()
		setPlayStatus(false)
		setAppState(appState => ({ 
			...appState,
			firstTimer:false
		}))
		window.localStorage.setItem("soundstatus", "off")
		if(appState.curPage === 0) {
			setTimeout(() => {
				setHoverability(true)
			}, 5000)
		} else {
			setHoverability(true)
		}
	}
	const height = use100vh()

	useEffect(() => {
		if (!window.localStorage.getItem("soundstatus")) {
			setHoverability(false)
			setAppState(appState => ({ 
				...appState,
				firstTimer: true
			}))
		} else if(window.localStorage.getItem("soundstatus") == 'on') {
			setHoverability(false)
			setPlayStatus(true)
			music.current.audioEl.current.play()
			setAppState(appState => ({ 
				...appState,
				firstTimer: false
			}))
			setTimeout(function() {
				if(window.location.pathName === "/") {
					setTimeout(() => {
						setHoverability(true)
					},4000)
				} else {
					setHoverability(true)
				}
			}, 100)
			
			
		} else if(window.localStorage.getItem("soundstatus") == 'off') {

			setPlayStatus(false)
			music.current.audioEl.current.pause()
			setHoverability(false)
			setAppState(appState => ({ 
				...appState,
				firstTimer: false
			}))
			if(window.location.pathname === "/") {

				setTimeout(() => {
					setHoverability(true)
				},4000)
			} else {
				setHoverability(true)
			}
		}

	},[])

	return (
		<>
			{!hoverability &&
				<VideoHolder
					key={0}
					className='active'
					css={css`
						height: ${height}px;
						> div > div {
							height: ${height}px;
						}
					`}
				>
					<VideoOverlay
						css={css`
							height: ${height}px;
						`}
					/>
					<VideoModule video={video} />
				</VideoHolder>
			}
			<IntroSoundBar
				className={hoverability ? 'hover' : 'no-hover'}
				status={appState.firstTimer === false}
				css={css`
                    height: ${height}px;
				`}
				variants={v.initialVideoVariants}
				initial='start'
				animate='finish'
			>
				<IntroLogo />
				<Headline
					variants={v.textVariants}
					initial="hidden"
					animate="visible"
				>
					Would you like to experience Umbrella Factor with sound?
				</Headline>
				<ButtonHolder>
					<SoundButtonNo
						onClick={pauseMusic}
						variants={v.noSoundButtonVariants}
						initial="hidden"
						animate="visible"
					>
						Sound off
					</SoundButtonNo>
					<CTA
						onClick={playMusic}
						variants={v.yesSoundButtonVariants}
						initial="hidden"
						animate="visible"
					>
						Sound on
					</CTA>
				</ButtonHolder>
			</IntroSoundBar>
			<AudioWrapper status={appState.firstTimer === false}>
				{playStatus &&
					<Button active="true" onClick={pauseMusic}><img src={soundOn} /></Button>
				}
				{!playStatus &&
					<Button onClick={playMusic}><img src={soundOff} /></Button>
				}
				<ReactAudioPlayer ref={music}>
					<source src={myAudio} type="audio/mpeg" />
					Your browser does not support the audio element.
				</ReactAudioPlayer>
			</AudioWrapper>
		</>
	)
}

export default SoundBar

const Button = styled.button`
	background-color:transparent;
	display:inline-block;
	img {
		width: 2.5rem;
	}
`
const AudioWrapper = styled.div`
	position:fixed;
	z-index:99999;
	bottom: 10px;
	left: 35px;
	transition:opacity .3s;
	opacity: ${props => props.status ? 1 : 0};
`

const IntroSoundBar = styled(motion.div)`
	background-color: rgba(0, 0, 0, 1);
	${modal}
	flex-direction:column;
	opacity:1;
	transition:opacity .3s;
	z-index:99999;
	&.hover {
		pointer-events:none;
	}
	${({status}) =>
		status && css`
			opacity:0;
		`
	}
`
const Headline = styled(motion.h2)`
	${pStyle}
	color:${theme.colors.white};
	padding:30px;
	line-height: 1.5;
	font-size: 16px;
	text-align: center;

	@media(min-width:${theme.breakpoints.navMobile}) {
		font-size: 18px;
	}
`
const ButtonHolder = styled(motion.div)`
    display:flex;
    justify-content:center;
`

const SoundButtonNo = styled(motion.button)`
	font-family: ${theme.fonts.body};
	font-size: ${theme.fontSizes.content}px;
    color:${theme.colors.white};
    background-color:transparent;
    display:inline-block;
	margin-right: 16px;
`
