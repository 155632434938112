export const containerVariants = {
    inactive: {
        opacity:0
    },
    active: {
        opacity:1
    },
    transition: {
        delay:0,
        duration: .01,
        when: 'beforeChildren'
    }
}

export const controlVariants = {
    inactive: {
        height:0,
        originY:'top'
    },
    active: ( custom ) => ({
        height: '100%',
        transition: {
            delay: .5 * custom,
            duration: .2,
            when: 'beforeChildren'
        }
    })
}
export const pageTitleVariants = {
    inactive: {
        opacity:0
    },
    active: {
        opacity:1,
        transition: {
            duration: .2,
            delay:.1
        }
    }
}
export const videoVariants = {
    
    hidden: {
        opacity:0,
    },
    visible: {
        opacity:1,
        transition: {
            duration:.1,
            delay:.0,
        }
    }
}
export const headerContainerVariants = {
    
}

export const initialVideoVariants = {
    start:{
        backgroundColor: 'rgba(0, 0, 0, 1)',
    },
    finish: {
        backgroundColor: 'rgba(0, 0, 0, .7)',
        transition: {
            delay:3,
            duration: 1.5,
			ease: 'easeIn',
        }
    },
    
}

export const textVariants = {
		hidden: {
			opacity: 0,
			y: 10,
		},
		visible: {
			opacity: 1,
			y:0,
			transition: {
				delay: 3.5,
				duration: 0.6,
				ease: 'easeInOut',
			y: { type: "spring", stiffness: 100 },
			},
		}
	};

export const noSoundButtonVariants = {
		hidden: {
			opacity: 0,
            y: 10,
            pointerEvents:'none'
		},
		visible: {
			opacity: 1,
            y:0,
            pointerEvents: 'auto',
			transition: {
				delay: 3.8,
				duration: 0.4,
				ease: 'easeInOut',
			y: { type: "spring", stiffness: 100 },
			},
		}
	};

export const yesSoundButtonVariants = {
		hidden: {
			opacity: 0,
			y: 10,
		},
		visible: {
			opacity: 1,
			y:0,
			transition: {
				delay: 4,
				duration: 0.4,
				ease: 'easeInOut',
			y: { type: "spring", stiffness: 100 },
			},
		}
	};
